<template>
  <ion-page class="page">
    <TopBar />
    <div class="pt-3 px-2 w-100">
      <h1 class="title mb-3">Your Account Settings</h1>
      <div class="w-100">
        <div class="change-title">Password</div>
        <div class="d-flex align-items-center mt-2">
          <div class="value mt-1">●●●●●●●●</div>
          <ion-button @click="() => (isPasswordModalOpen = true)" class="ml-auto change-btn">
            Change password
          </ion-button>
        </div>
      </div>

      <hr class="my-3 w-100" />
      <div class="w-100">
        <div class="change-title">Username</div>

        <div class="d-flex align-items-center mt-2">
          <div class="value mt-1">{{ user.username }}</div>
          <ion-button @click="() => (isUsernameModalOpen = true)" class="ml-auto change-btn">
            Change username
          </ion-button>
        </div>
      </div>

      <hr v-if="featureFlags.emailUpdate" class="my-3 w-100" />
      <div v-if="featureFlags.emailUpdate" class="w-100">
        <div class="change-title">Email</div>

        <div v-if="user.email_configs?.new_email" class="my-2 error-msg" style="text-align: left">
          Your new email address <strong> "{{ user.email_configs?.new_email }}" </strong> cannot be used until its
          verified.
        </div>
        <div
          v-if="featureFlags.emailUpdate && user.email_configs?.new_email"
          class="my-1 error-msg"
          style="text-align: left; font-size: 12px"
        >
          Don't see a verification email? Try checking your spam inbox or
          <strong style="font-size: 12px" @click="resend" class="clickable-item-hov error-msg">have it resent</strong>.
        </div>

        <div class="d-flex align-items-center flex-wrap mt-2">
          <div class="value">{{ user.email }}</div>
          <ion-badge
            style="font-size: 12px"
            class="px-1 ml-1 d-flex align-items-center"
            :color="get(user, 'is_email_verified', true) ? 'success' : 'danger'"
          >
            <ion-icon
              style="font-size: 12px; margin-right: 1px"
              :icon="get(user, 'is_email_verified', true) ? checkmarkSharp : warningOutline"
            />
            {{ get(user, 'is_email_verified', true) ? 'Verified' : 'Unverified' }}
          </ion-badge>
          <ion-button @click="() => (isEmailModalOpen = true)" class="ml-auto change-btn"> Change email </ion-button>
        </div>

        <div
          v-if="!user.email_configs?.new_email && !get(user, 'is_email_verified', true)"
          class="my-1 error-msg"
          style="text-align: left; font-size: 12px"
        >
          Don't see a verification email? Try checking your spam inbox or
          <strong style="font-size: 12px" @click="resend" class="clickable-item-hov error-msg">have it resent</strong>.
        </div>
      </div>
      <div v-if="user?.is_email_verified" class="mt-3 mb-4 email-verif-form">
        <form @submit.prevent="submitCode" class="d-flex align-items-center">
          <ion-input
            class="email-form mr-1 c-input"
            v-model="verificationCode"
            placeholder="Enter verification code"
          ></ion-input>
          <ion-button type="submit" size="large" class="email-verif-submit">
            Verify
          </ion-button>
        </form>
      </div>
    </div>

    <ChangePasswordModal :isOpen="isPasswordModalOpen" @dismissModal="isPasswordModalOpen = false" />
    <ChangeUsernameModal :isOpen="isUsernameModalOpen" @dismissModal="isUsernameModalOpen = false" />
    <ChangeEmailModal :isOpen="isEmailModalOpen" @dismissModal="isEmailModalOpen = false" />
  </ion-page>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import ChangePasswordModal from './ChangePasswordModal.vue';
import ChangeUsernameModal from './ChangeUsernameModal.vue';
import ChangeEmailModal from './ChangeEmailModal.vue';
import TopBar from '@/shared/components/TopBar.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import { emailVerification } from '@/shared/actions/users';
import { checkmarkSharp, warningOutline } from 'ionicons/icons';
import { resendEmail } from '@/shared/actions/users';
import { toast } from '@/shared/native';

const { user, hydrateUser } = authStore();
const formLoading = ref(false);
const verificationCode = ref('');

const resend = async () => {
  try {
    await resendEmail();
    toast.show('Email sent successfully.', 'nonative', 'success');
  } catch (error) {
    toast.show('Resend email limit reached. Try again in 6 hours.', 'nonative', 'danger');
  }
};

const submitCode = async () => {
  if (formLoading.value) return;
  const code = verificationCode.value?.trim()
  if (!code) return;
  if (!/^\d{6}$/.test(code)) {
    toast.show('Invalid verification code.', 'nonative', 'danger');
    return;
  }
  formLoading.value = true;
  try {
    const res = await emailVerification(code);
    if (res.is_email_verified) {
      toast.show('Email verified successfully!', 'nonative', 'success');
      if (user.value?.email_configs?.new_email) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } else if (res.message) {
      toast.show(res.message, 'nonative', 'primary');
    }
  } catch (e) {
    toast.show('Email verification failed', 'nonative', 'danger');
  } finally {
    formLoading.value = false;
  }
};

const isPasswordModalOpen = ref(false);
const isUsernameModalOpen = ref(false);
const isEmailModalOpen = ref(false);
</script>

<style scoped lang="sass">
.value
  font-size: 18px !important
.change-title
  width: 100%
  text-align: start
  font-weight: bold
  font-size: 18px
.page
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 20px
  *
    font-size: 0.9rem
.logo
  height: 50px
.reset-password-form
  text-align: start
  width: 100%
  margin-top: 16px
  .reset-password-form
    margin-top: 16px

.title
  margin-top: 0 !important
  font-size: 24px !important

.c-input
  margin-bottom: 16px
.error-msg
  color: var(--ion-color-danger) !important

.change-btn
  width: 150px

.email-verif-form
  max-width: 300px
.email-verif-submit
  bottom: 8px
  position: relative
</style>
