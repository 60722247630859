<template>
  <ion-modal
    class="modal-small"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="d-flex flex-column justify-content-between align-items-center h-100">
        <div class="w-100">
          <form class="reset-password-form" @submit.prevent="onSubmit">
            <div class="label mb-1 mt-3">Current Password</div>
            <ion-password-input
              id="currPassword"
              v-model="currPassword"
              :color="validatorColor(pwOk)"
              class="c-input mt-1"
              placeholder="Enter your current password"
              autocapitalize="off"
              type="password"
              @ionInput="pwOk = true"
            />

            <div class="label mb-1 mt-3">New Password</div>
            <ion-password-input
              id="newPass"
              v-model="newPassword"
              :color="validatorColor(pwOk)"
              class="c-input mt-1"
              placeholder="Enter new password (8+ characters)"
              autocapitalize="off"
              type="password"
              @ionInput="pwOk = true"
            />

            <div class="label mb-1 mt-3">Repeat Password</div>
            <ion-password-input
              id="repeatPass"
              v-model="repeatPassword"
              :color="validatorColor(pwOk)"
              class="c-input mt-1"
              placeholder="Repeat your new password"
              autocapitalize="off"
              type="password"
              @ionInput="pwOk = true"
            />

            <div v-if="!pwOk" class="error-msg mb-2 mt-3">
              <span v-if="newPassword !== repeatPassword">Entered passwords do not match</span>
              <span v-else>Password must be 8+ characters long</span>
            </div>
          </form>
        </div>
        <div class="actions d-flex mt-3">
          <ion-button
            :loading="loading"
            :disabled="!currPassword || !newPassword || !repeatPassword || loading"
            class="reset-button"
            @click="onSubmit"
          >
            Change Password
          </ion-button>

          <ion-button color="medium" @click="dismissModal"> Cancel </ion-button>
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native/toast';
import { changeLoggedInUserPassword } from '@/shared/services/auth';
import IonPasswordInput from '@/shared/components/ion-password-input.vue';
import { passwordIsValid } from '@/shared/utils/auth';

defineProps({
  modalTitle: {
    type: String,
    default: 'Change password',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['deleteRelationship', 'dismissModal']);

const dismissModal = () => {
  emits('dismissModal');
};

const deleteRelationship = () => {
  emits('deleteRelationship');
  emits('dismissModal');
};

const currPassword = ref('');
const newPassword = ref('');
const repeatPassword = ref('');
const pwOk = ref(true);
const loading = ref(false);

const handleError = async (e: any) => {
  loading.value = false;
  let message = 'Something went wrong when resetting the password. Please try again later';
  try {
    if (e && e.response) {
      if (e.response.status === 403) {
        message = 'Current password is incorrect.';
      } else if (e.response.data && e.response.data.password) {
        message = e.response.data.password[0];
      }
    }
  } catch {}
  await toast.show(message, 'nonative', 'danger');
};

const onSubmit = async () => {
  pwOk.value = passwordIsValid(newPassword.value) && newPassword.value === repeatPassword.value;

  if (!pwOk.value) return;

  try {
    loading.value = true;

    await changeLoggedInUserPassword({
      old_password: currPassword.value,
      new_password: newPassword.value,
    });
    await toast.show('Your password has been updated successfully.', 'nonative', 'success');

    currPassword.value = newPassword.value = repeatPassword.value = '';
    dismissModal();
  } catch (e) {
    return handleError(e);
  } finally {
    loading.value = false;
  }
};

const validatorColor = (value: boolean) => {
  return !value ? 'danger' : '';
};
</script>

<style lang="sass" scoped>
.error-msg
  color: var(--ion-color-danger)
  font-size: 14px
</style>
