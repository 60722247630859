<template>
  <ion-modal
    class="modal-small"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="w-100">
          <div class="d-flex justify-content-end mt-3 align-items-center">
            <ion-input
              :minlength="0"
              :maxlength="15"
              :placeholder="updatedUser.username"
              v-model="usernameExample"
              @input="check"
              class="c-input"
            />
            <ChLoading size="sm" v-if="checking" class="spinner mr-2" />
          </div>

          <div class="hint mt-2">characterhub.com/profile/{{ usernameExample }}</div>
          <div class="hint mt-1"><i class="ti-alert" /> Letters, numbers, and the following characters are allowed: ._-</div>
        </div>
        <div v-if="!usernameAvailable" class="error-msg mt-3">Username already exists</div>
        <div class="actions d-flex mt-3">
          <ion-button :disabled="!usernameExample || isSaving || !usernameAvailable || checking" @click="save">
            Save
          </ion-button>

          <ion-button color="medium" @click="dismissModal"> Cancel </ion-button>
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import { getUserByUsername } from '@/shared/actions/users';
import { authStore } from '@/shared/pinia-store/auth';

const { updateUser, user } = authStore();

const isSaving = ref(false);
const checking = ref(false);

defineProps({
  modalTitle: {
    type: String,
    default: 'Change username',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['dismissModal']);

const dismissModal = () => {
  emits('dismissModal');
};

const updatedUser = cloneDeep(user);

onMounted(() => {
  usernameExample.value = updatedUser.value.username;
});

const usernameExample = ref('');
const usernameAvailable = ref(true);

const check = debounce(async function () {
  try {
    checking.value = true;
    await getUserByUsername(usernameExample.value);
    usernameAvailable.value = false;
  } catch (error) {
    usernameAvailable.value = true;
  } finally {
    checking.value = false;
  }
}, 300);

const save = async () => {
  try {
    isSaving.value = true;
    await updateUser(user.value.id, { username: usernameExample.value });
    dismissModal();
  } catch {
    toast.show('Error changing username', 'nontive', 'danger');
  } finally {
    isSaving.value = false;
  }
};
</script>

<style lang="sass" scoped>
.spinner
  opacity: 0.5
  position: absolute
  z-index: 22
  width: 16px
  height: 16px
.hint
  color: #999
  font-size: 12px

.modal-small
  --height: 250px !important
.error-msg
  color: var(--ion-color-danger)
  font-size: 14px
  width: 100%
  text-align: start
</style>
